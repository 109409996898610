import Head from "next/head";
import { AuctionContactNumber } from "@/utils/utils";

export const SchemaMarkup = (
  t, lang,
  arabicSubUrl = "",
  englishSubUrl = "",
  props = {}
) => {
  const {
    schemaType = "",
    breadcrumbsList = [],
    propertyDetails = {},
    propertyImage = "",
  } = props;

  const HomeSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Corporation",
      name: t("schema.name"),
      url: `${process.env.NEXT_PUBLIC_HOST_URL}${
        lang == "en" ? `/en${englishSubUrl}` : arabicSubUrl
      }`,
      telephone: AuctionContactNumber,
      logo: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${lang == 'en' ? '' : '-ar'}.svg?v1`,
      image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${lang == 'en' ? '' : '-ar'}.svg?v1`,
      address: {
        "@type": "PostalAddress",
        addressLocality: t("schema.addressLocality"),
        addressRegion: t("schema.addressRegion"),
        sameAs: [
          "https://www.facebook.com/WasaltOfficial",
          "https://twitter.com/WasaltOfficial",
          "https://instagram.com/wasaltofficial",
          "https://www.youtube.com/channel/UCwV9YXTxwCNu-XhmsmWKJFA/featured",
          "https://www.linkedin.com/company/wasaltofficial",
        ],
      },
    };
  };
  const BreadcrumbsMarkupSchema = () => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbsList.map(({ name, item }, index) => {
        return {
          "@type": "ListItem",
          position: index + 1,
          name,
          item: `${process.env.NEXT_PUBLIC_HOST_URL}${item}`,
        };
      }),
    };
  };

  const SingleFamilyResidence = () => {
    const {
      propertyInfo: {
        title,
        description,
        city,
        bidStartingPrice,
        highestBid,
      },
      attributes,
      propertyOwner: { phone } = {},
    } = propertyDetails;
  
    const residenceDetail = {
      "@context": "https://schema.org",
      "@type": "SingleFamilyResidence",
      "image": propertyImage,
    };
  
    // Extract title and leaseLength
    if (title) {
      residenceDetail["name"] = title.split(',')[0];
      residenceDetail["leaseLength"] = {
        "@type": "QuantitativeValue",
        unitText: title.split(',')[0],
      };
    }
  
    // Add description
    residenceDetail["description"] = description;
  
    // Extract bedrooms and bathrooms
    const getAttributeValue = (key) =>
      attributes?.find(({ key: attrKey }) => attrKey === key)?.value;
    const bedrooms = getAttributeValue("noOfBedrooms");
    const bathrooms = getAttributeValue("noOfBathrooms");
  
    if (bedrooms) {
      residenceDetail["numberOfRooms"] = bedrooms;
      residenceDetail["numberOfBedrooms"] = bedrooms;
    }
  
    if (bathrooms) residenceDetail["numberOfBathroomsTotal"] = bathrooms;
  
    // Build address if necessary
    if (city) {
      residenceDetail["address"] = { "@type": "PostalAddress" };
      residenceDetail.address["addressLocality"] = city;
    }
  
    // Add offers if price exists
    if (bidStartingPrice || highestBid) {
      residenceDetail["offers"] = [
        {
          "@type": "Offer",
          price: bidStartingPrice || highestBid,
        },
      ];
    }
  
    // Add phone if available
    residenceDetail["telephone"] = phone;
    return residenceDetail;
  };
  
  const breadcrumbsSchemaData = schemaType == "BreadcrumbList" || schemaType == "DetailPage"
    ? JSON.stringify(BreadcrumbsMarkupSchema())
    : ""
  return (
    <Head>
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_HOST_URL}${
          lang == "en" ? `/en${englishSubUrl}` : arabicSubUrl
        }`}
      />
      <link
        rel="alternate"
        hreflang="ar"
        href={`${process.env.NEXT_PUBLIC_HOST_URL}${arabicSubUrl}`}
      />
      <link
        rel="alternate"
        hreflang="en"
        href={`${process.env.NEXT_PUBLIC_HOST_URL}/en${englishSubUrl}`}
      />
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${process.env.NEXT_PUBLIC_HOST_URL}${arabicSubUrl}`}
      />
      {schemaType && (
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html:
            schemaType == "Home"
              ? JSON.stringify(HomeSchema())
              : breadcrumbsSchemaData,
        }}
      />
      )}
      {schemaType == "DetailPage" && (
        <script type="application/ld+json"
        dangerouslySetInnerHTML={{__html: JSON.stringify(SingleFamilyResidence())}}
        />
      )}
    </Head>
  );
};


